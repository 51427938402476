import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Backdrop, Button, CircularProgress, InputBase,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserServices from 'services/UserServices'
import { RootState } from 'Store'
import './AlternativeUserCreation.scss'

type Props = {
  goTo: (step: number) => void;
}
export default function AlternativeUserCreation({ goTo }: Props): ReactElement {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [lastName, setName] = useState('')
  const [firstName, setFirstname] = useState('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const { createUserLoading } = useSelector((state: RootState) => state.users)

  const createUser = () => {
    dispatch(UserServices.createUser({ email, lastName, firstName }))
  }

  const validateEmail = (newEmail: string) => {
    const regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')
    setEmailError(!regex.test(newEmail))
  }

  const checkErrors = () => !(email.trim() && !emailError && lastName.trim() && firstName.trim())
  return (
    <div style={{ position: 'relative' }} className="w-100">
      <Backdrop
        open={createUserLoading}
        className="backdrop"
      >
        <CircularProgress size={80} color="inherit" />
      </Backdrop>
      <div className="main-modal-alternative w-100">
        <div className="field-container">
          <div className="alternative-textfield-label">{terms.User.email}</div>
          <InputBase
            className="alternative-username-textfield"
            error={emailError && email.trim() !== ''}
            value={email}
            inputProps={{ maxLength: 100 }}
            onChange={evt => {
              setEmail(evt.target.value)
              validateEmail(evt.target.value)
            }}
          />
          {emailError && email.trim() !== '' && (<div className="error-message">{terms.User.wrongEmail}</div>)}

        </div>
        <div className="field-container">
          <div className="alternative-textfield-label">{terms.User.lastName}</div>
          <InputBase
            className="alternative-username-textfield"
            value={lastName}
            inputProps={{ maxLength: 50 }}
            onChange={evt => setName(evt.target.value)}
          />
        </div>
        <div className="field-container">
          <div className="alternative-textfield-label">{terms.User.firstName}</div>
          <InputBase
            className="alternative-username-textfield"
            value={firstName}
            inputProps={{ maxLength: 50 }}
            onChange={evt => setFirstname(evt.target.value)}
          />
        </div>
      </div>

      <div className="d-flex w-100 flex-column align-items-center justify-content-end footer-modal">
        <div className="d-flex w-100 justify-content-center noUsername-container">
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => goTo(1)}
            className="button-no-username button-back"
          >
            {terms.User.returnToForm}

          </Button>
        </div>

        <div className="d-flex w-75 align-items-center justify-content-center">
          <Button
            disabled={checkErrors() || createUserLoading}
            className="button-create"
            variant="contained"
            onClick={createUser}
          >

            {terms.User.createUsername}

          </Button>

        </div>

      </div>
    </div>
  )
}
