import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton } from '@mui/material'
import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import SearchInput from 'components/SearchInput/SearchInput'
import TokenModal from 'components/TokenInfo/TokenInfo'
import { debounce } from 'lodash'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddUsersMode, setMemberSearch, setSelectedGroup } from 'reducers/group'
import { setTokenModal } from 'reducers/token'
import { Group } from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'
import { RootState, store } from 'Store'
import CustomAutocomplete from './CustomAutocomplete'
import './DetailsBar.scss'
import { canManage } from './utils'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setMemberSearch(val)),
  200,
)

type Props = {
  group: Group;
  length: string;
  loading: boolean;
}
export default function DetailsBar({ group, length, loading }: Props): ReactElement {
  const dispatch = useDispatch()
  const { addUsersMode, memberSearch, selectedGroup } = useSelector((state: RootState) => state.groups)
  const { userSearch } = useSelector((state: RootState) => state.users)
  const { showToken } = useSelector((state: RootState) => state.tokens)

  useEffect(() => {
    dispatch(UserServices.getUsers({ search: userSearch, page: 1 }))
  }, [userSearch])

  const closeMenu = () => {
    dispatch(setSelectedGroup(undefined))
  }
  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setMemberSearch(value))
      return
    }
    debouncedSearch(value)
  }

  const changeMode = (mode: boolean) => {
    dispatch(setAddUsersMode(mode))
  }

  useEffect(() => () => {
    changeMode(false)
  }, [selectedGroup])

  const addUser = (id: string) => {
    dispatch(GroupServices.addUserToGroup({ groupId: group.id, userId: id }))
  }

  const getPermissions = () => {
    dispatch(GroupServices.getGroupPermissions(selectedGroup.id))
    dispatch(setTokenModal(true))
  }

  return (
    <div className="details-bar">
      <div className="group-menu">
        <div className="details-group">
          {!loading && (
            <>
              <span className="name">
                {`${group.name}`}
              </span>
              <IconButton className="group-info-perm" onClick={getPermissions}>
                <InfoIcon />
              </IconButton>
              <span className="count">
                {`(${length} ${+length <= 1 ? terms.Table.user : terms.Table.users})`}
              </span>
            </>
          )}

        </div>

        <div className="usergroup-close">
          <IconButton onClick={closeMenu}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      {addUsersMode ? (
        <div className="buttons-container" style={{ flex: 1 }}>
          <CustomAutocomplete onSelect={addUser} />
        </div>
      ) : (
        <div className="buttons-container" style={{ flex: canManage() ? 1 : 0.4 }}>
          <SearchInput value={memberSearch} onChange={newValue => handleChange(newValue)} />

          {canManage() && (
            <CustomButton
              onClick={() => changeMode(true)}
              value={terms.Group.addToGroup}
              startIcon={<AddIcon />}
            />
          )}
        </div>
      )}

      {showToken && (<TokenModal />)}

    </div>
  )
}
