import { ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { PORTAL_URL } from 'config/config'
import { RootState } from 'Store'
import history from 'customHistory'

import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ appName, innerComponent }: Props): ReactElement {
  const { homePath } = useSelector((state: RootState) => state.app)

  return (
    <div className="topbar d-flex align-items-center justify-content-between px-3">
      <header role="banner" className="d-none d-xl-flex">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
        </div>
        <div
          className="d-flex align-items-center"
          aria-hidden
          role="button"
          onClick={() => history.replace(homePath || '/')}
        >
          <h1 className="text-uppercase pl-3 pt-1 mb-0 app-name">{appName}</h1>
        </div>
      </header>
      {innerComponent}
      <AccountMenu />
    </div>
  )
}

TopBar.defaultProps = defaultProps
