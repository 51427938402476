/* eslint-disable max-len */
export const terms = {
  Common: {
    copy: 'Copier',
    copied: 'Copié !',
    pendingQuery: 'Requête en cours...',
    add: 'Ajouter',
    to: 'aux',
    warning: 'Attention',
    ok: 'OK',
    noResults: 'Pas de résultats',
    loading: 'Chargement',
  },
  Error: {
    forbidden: {
      appTitle: "Vous n'avez pas les droits nécessaires pour accéder à cette application",
      appSubtitle: 'Veuillez contacter votre administrateur',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
    conflict: 'Cet objet est en conflit. Réglez le conflit et réessayez.',
    unknown: 'Une erreur est survenue.',
    notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
    badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
  },
  Table: {
    username: 'CP',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom',
    enabled: 'Actif',
    createdTimestamp: 'Date d\'enregistrement',
    id: 'ID',
    can_delegate: 'Rôle',
    action: 'Action',
    users: 'Utilisateurs',
    token_name: 'Nom du token',
    token_type: 'Type',
    expire_on: 'Date d\'expiration',
    jwt: 'JWT',
    status: 'Statut',
    noResults: 'Pas de résultats correspondant à votre recherche',
    permission: 'Permissions',
    role_name: 'Rôle',
    doc_link: 'Lien vers la documentation',
    user: 'Utilisateur',
  },
  User: {
    username: 'CP',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom',
    enabled: 'Actif',
    createdTimestamp: 'Date d\'enregistrement',
    id: 'ID',
    can_delegate: 'Rôle',
    action: 'Action',
    users: 'Utilisateurs',
    noResults: 'Aucun utilisateur trouvé',
    enableSuccess: 'L\'utilisateur a bien été activé',
    disableSuccess: 'L\'utilisateur a bien été désactivé',
    search: 'Rechercher',
    create: 'Créer un nouvel utilisateur',
    manageGroup: 'Gestion des groupes de',
    memberOf: 'Membre des',
    followingGroups: 'groupes suivants',
    availableGroups: 'Groupes disponibles',
    remove: 'Retirer des',
    writeUsernames: 'Entrez un ou plusieurs CP SNCF',
    noUsername: 'Pas de CP SNCF ?',
    addUsername: 'Ajouter le CP',
    addUsernames: 'Ajouter les',
    creationSuccess: 'Un utilisateur a bien été créé',
    usernameError: 'entrée n\'a pas pu être ajoutée à la liste :',
    usernameErrors: 'entrées n\'ont pas pu être ajoutées à la liste :',
    createThe: 'Créer les',
    createdUsers: 'utilisateurs créés',
    createUsername: 'Créer un utilisateur',
    returnToForm: 'Retour au formulaire par CP',
    creationFail: 'Aucun utilisateur créé',
    wrongEmail: 'L\'email entré est au mauvais format (mail@exemple.fr)',
    removeFromGroup: 'Retirer du groupe',
    noGroups: 'Membre d\'aucun groupe :',
    memberOfOne: 'Membre d\'un groupe :',
  },
  Group: {
    groups: 'Groupes',
    notFound: 'Pas de groupes trouvés',
    removeMember: 'Retirer des membres',
    admin: 'Admin',
    member: 'Membre',
    addToGroup: 'Ajouter au groupe',
    removedUser: 'Utilisateur retiré du groupe',
    addedUser: 'Utilisateur ajouté au groupe',
    modificationSuccess: 'Groupes modifiés avec succès',
    partialModification: 'Tous les groupes n\'ont pas pu être modifiés',
    assignAdmin: 'Ajouter aux administrateurs',
    removeAdmin: 'Retirer des administrateurs',
    assignMember: 'Ajouter aux membres',
    modifiedUser: 'Utilisateur modifié',
    searchUserToAdd: 'Rechercher un utilisateur Cerbere à ajouter au groupe',
    importUsernames: 'Importer une liste de CP',
    importInfo: '(Séparez les CP par un espace ou un retour à la ligne)',
    addedUsers: 'utilisateurs ajoutés à',
    addFail: 'Aucun utilisateur ajouté',
    usernameError: 'CP n\' a pas pu être ajouté à la liste :',
    usernameErrors: 'CP n\'ont pas pu être ajoutés à la liste :',
    infoCreatedUser: 'CP a été créé automatiquement dans cerbere, vous pouvez le retrouver dans la liste d’utilisateurs :',
    infoCreatedUsers: 'CP ont été créés automatiquement dans cerbere, vous pouvez les retrouver dans la liste d’utilisateurs :',
  },
  Token: {
    accepted: 'Actif',
    owned: 'Actif',
    pending: 'En attente',
    pending_delete: 'En attente de suppression',
    rejected: 'Refusé',
    disable: 'Désactivé',
    tokens: 'Tokens',
    personal: 'Personnel',
    external: 'Externe',
    service: 'Service',
    showList: 'Voir la liste',
    permissionList: 'Liste des permissions',
    expired: 'Expiré',
  },
  Help: {
    faq: 'FAQ',
  },
}

export default terms
