/* eslint-disable no-multi-str */
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ReactElement } from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import './Help.scss'
import terms from 'common/terms'

const faq = [
  {
    title: 'Ajouter un utilisateur', content: "Depuis la page Utilisateurs, cliquez sur Créer un utilisateur, puis \
  entrez son CP et appuyez sur Ajouter. Si l'utilisateur n'a pas de CP, cliquez sur Pas de CP SNCF ? et remplissez \
  les informations suivantes : email, nom, prénom et appuyez sur Ajouter. \n \n Vous pouvez aussi ajouter plusieurs  \
  utilisateurs en séparant les CP d'un espace ou d'un retour à la ligne'. \n \n \
  Une fois créé, l'utilisateur apparaîtra dans la liste.",
  },
  {
    title: 'Assigner un utilisateur à un groupe',
    content: "Depuis la page Groupes, sélectionnez un groupe, puis cliquez sur Ajouter au groupe. Un champ \
    de recherche s'affiche. \nVous pouvez rechercher un utilisateur à ajouter ou cliquer sur Importer une liste de CP \
afin d'ajouter plusieurs utilisateurs au groupe, en séparant les CP d'un espace ou d'un retour à la ligne. Si \
un CP n'existe pas, il sera automatiquement créé.",
  },
]

export default function Help(): ReactElement {
  return (
    <div className="help-container">
      <div className="help-bar">
        {terms.Help.faq}
      </div>
      <div className="help-main">
        {faq.map(question => (
          <Accordion elevation={0} disableGutters className="accordion" key={question.title}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-title"
            >
              {question.title}
            </AccordionSummary>
            <AccordionDetails className="accordion-summary">
              {question.content}
            </AccordionDetails>
          </Accordion>
        ))}

      </div>
    </div>
  )
}
