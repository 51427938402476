/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
import CustomCopyCell from 'components/CustomCopyCell/CustomCopyCell'
import { Column } from 'components/CustomTable/types'
import GroupAction from 'components/GroupAction/GroupAction'
import RoleCell from 'components/RoleCell/RoleCell'
import { ReactElement } from 'react'
import { User } from 'services/cerbereTypes'
import { canDelegate, canManage } from './utils'

const columnsHeader: Column[] = [
  {
    propertyName: 'username',
    align: 'left',
    width: '130px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'email',
    align: 'left',
    width: '250px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'firstName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'lastName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'can_delegate',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (_o, user: unknown): ReactElement => (<RoleCell user={user as User} />),
    show: () => true,
  },
  {
    propertyName: 'action',
    align: 'left',
    width: '85px',
    copy: false,
    formatter: (_o, user: unknown): ReactElement => (<GroupAction userId={(user as User).id} />),
    show: () => canManage() || canDelegate(),
  },
]

export default columnsHeader
