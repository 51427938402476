import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import terms from 'common/terms'
import get from 'lodash/get'
import {
  FeedbackState, setError, setMessage, setMessageReady,
} from 'reducers/feedback'
import { RootState, store } from 'Store'
import './FeedbackSnackBar.scss'
import { DEFAULT_AUTOCLOSE_DURATION, handleFeedback, Severity } from './utils'

export default function FeedbackSnackBar(): ReactElement {
  const dispatch = useDispatch()
  const { feedback, message, messageReady } = useSelector((state: RootState) => state.feedback as FeedbackState)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState<Severity>(Severity.error)
  const [autoCloseDuration, setAutoCloseDuration] = useState<number | null>(DEFAULT_AUTOCLOSE_DURATION)

  useEffect(() => {
    if (feedback) {
      handleFeedback(feedback, setOpen, setSeverity, setAutoCloseDuration)
    }
  }, [feedback])

  const onClose = () => {
    setOpen(false)
    dispatch(setMessageReady(false))
    setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
    store.dispatch(setError())
  }

  const handleExited = () => {
    dispatch(setMessage(''))
  }

  return (
    <Snackbar
      id="feedback-snackbar"
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={autoCloseDuration}
    >
      <Alert severity={severity} variant="filled">
        {messageReady ? message
          : get(terms, message)}
      </Alert>
    </Snackbar>
  )
}
