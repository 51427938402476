import { store } from 'Store'

const ROOT_PERMISSIONS = 'cerbere::ROOT'
const EDITOR_PERMISSIONS = 'cerbere::EDITOR'
const CAN_ADD_OUTSIDE_USER_PERMISSIONS = 'cerbere::CAN_ADD_OUTSIDE_USER'
const ACCESS_PERMISSIONS = 'cerbere::ACCESS'

export const isRoot = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(ROOT_PERMISSIONS)
}

export const isEditor = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(EDITOR_PERMISSIONS)
}

export const canAddOutsideUser = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(CAN_ADD_OUTSIDE_USER_PERMISSIONS)
}

export const isUser = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(ACCESS_PERMISSIONS)
}
