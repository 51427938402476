import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Group, ServiceResponse, UserAssignResponse, UserResponse,
} from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'

export interface GroupState {
  services: ServiceResponse;
  servicesLoading: boolean;
  assignGroupLoading: boolean;
  groupsLoading: boolean;
  groupSearch: string;
  selectedGroup: Group;
  groupMembers: UserResponse;
  membersLoading: boolean;
  memberSearch: string;
  addUsersMode: boolean;
  userRemoved: boolean;
  userStatusChanged: boolean;
  userAdded: boolean;
  groupModal: boolean;
  assignUsersLoading: boolean
  assignedUsers: UserAssignResponse;
}

const initialGroup: Group = {
  id: '',
  name: '',
  path: '',
  children: [],
  members_count: '',
  user_can_delegate: false,
  user_can_managed: false,
  can_delegate: false,
  is_member: false,
  can_managed: false,
}

const initialAssign: UserAssignResponse = {
  users_creation_fail: [], users_assign_fail: [], users_assign_success: [], users_creation_success: [],
}

const initialState: GroupState = {
  services: { results: [], count: '0' },
  servicesLoading: false,
  assignGroupLoading: false,
  groupsLoading: true,
  groupSearch: '',
  selectedGroup: initialGroup,
  groupMembers: { results: [], count: '0', page_size: '0' },
  membersLoading: false,
  memberSearch: '',
  addUsersMode: false,
  userRemoved: false,
  userStatusChanged: false,
  userAdded: false,
  groupModal: false,
  assignUsersLoading: false,
  assignedUsers: initialAssign,
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupSearch: (state, action: PayloadAction<string>) => {
      state.groupSearch = action.payload
    },
    setSelectedGroup: (state, action: PayloadAction<Group | undefined>) => {
      if (state.selectedGroup.id === action.payload?.id) return

      if (action.payload === undefined) {
        state.selectedGroup = initialGroup
      } else {
        state.selectedGroup = action.payload
        state.membersLoading = true
      }
    },
    setMemberSearch: (state, action: PayloadAction<string>) => {
      state.memberSearch = action.payload
    },
    setAddUsersMode: (state, action: PayloadAction<boolean>) => {
      state.addUsersMode = action.payload
    },
    setGroupModal: (state, action: PayloadAction<boolean>) => {
      state.groupModal = action.payload
    },
    resetGroupSearch: state => {
      state.groupSearch = ''
      state.memberSearch = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(GroupServices.addUserToGroup.pending, state => {
      state.assignGroupLoading = true
      state.userAdded = false
    })
    builder.addCase(GroupServices.addUserToGroup.fulfilled, state => {
      state.assignGroupLoading = false
      state.userAdded = true
    })
    builder.addCase(GroupServices.removeUserFromGroup.pending, state => {
      state.assignGroupLoading = true
    })
    builder.addCase(GroupServices.removeUserFromGroup.fulfilled, state => {
      state.assignGroupLoading = false
      state.userRemoved = true
    })
    builder.addCase(GroupServices.getGroups.fulfilled, (state, action) => {
      state.services = action.payload
      state.groupsLoading = false
    })
    builder.addCase(GroupServices.getGroups.pending, state => {
      state.groupsLoading = true
      state.membersLoading = true
      state.selectedGroup = initialGroup
    })
    builder.addCase(GroupServices.getGroupMembers.pending, state => {
      state.membersLoading = true
      state.userRemoved = false
    })
    builder.addCase(GroupServices.getGroupMembers.fulfilled, (state, action) => {
      state.groupMembers = action.payload
      state.membersLoading = false
    })
    builder.addCase(GroupServices.assignAdmin.fulfilled, state => {
      state.userStatusChanged = true
    })
    builder.addCase(GroupServices.assignAdmin.pending, state => {
      state.userStatusChanged = false
    })
    builder.addCase(GroupServices.removeAdmin.fulfilled, state => {
      state.userStatusChanged = true
    })
    builder.addCase(GroupServices.removeAdmin.pending, state => {
      state.userStatusChanged = false
    })
    builder.addCase(GroupServices.assignUsers.pending, state => {
      state.assignedUsers = initialAssign
      state.assignUsersLoading = true
    })
    builder.addCase(GroupServices.assignUsers.fulfilled, (state, action) => {
      state.assignedUsers = action.payload
      state.assignUsersLoading = false
    })
    builder.addCase(GroupServices.assignUsers.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.assignedUsers = action.payload.data as UserAssignResponse
      }
      state.assignUsersLoading = false
    })
  },
})

export const {
  setGroupSearch, setSelectedGroup, setMemberSearch, setAddUsersMode, setGroupModal, resetGroupSearch,
} = groupsSlice.actions

export default groupsSlice.reducer
