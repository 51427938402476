import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@mui/material'
import terms from 'common/terms'
import { get } from 'lodash'
import { Order } from 'objects/types'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSort } from 'reducers/user'
import { RootState } from 'Store'
import { Column } from './types'

type Props = {
  cols: Column[];
}

export default function CustomTableHead({ cols }: Props): ReactElement {
  const dispatch = useDispatch()
  const { sort } = useSelector((state: RootState) => state.users)

  const handleSort = (column: string) => {
    let order: Order = 'asc'
    let field = column
    if (sort.field === column) {
      switch (sort.sort) {
        case 'asc':
          order = 'desc'
          break
        case 'desc':
          order = undefined
          field = ''
          break
        default:
          order = 'asc'
      }
    } else {
      order = 'asc'
    }

    dispatch(setSort({ field, sort: order }))
  }

  return (
    <TableHead className="table-header">
      <TableRow>
        {cols.map((headCell: Column) => (
          headCell.show()
          && (
            <TableCell
              key={headCell.propertyName}
              align="left"
              padding="none"
              sx={{ width: headCell.width }}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={sort.field === headCell.propertyName}
                  direction={sort.field === headCell.propertyName ? sort.sort : undefined}
                  onClick={() => handleSort(headCell.propertyName as string)}
                >
                  {get(terms, `Table.${headCell.propertyName}`)}
                </TableSortLabel>
              ) : get(terms, `Table.${headCell.propertyName}`)}

            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  )
}
