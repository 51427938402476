import { Button, Chip } from '@mui/material'
import { ReactComponent as RoundInfoIcon } from 'assets/icons/infoPanel.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import terms from 'common/terms'
import UsernameError from 'components/UserCreation/UsernameError'
import { union } from 'lodash'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import './SecondStep.scss'
import UsernamesCreated from './UsernamesCreated'

type Props = {
  handleClick: () => void;
}

export default function SecondStep({ handleClick }: Props): ReactElement {
  const { assignedUsers, selectedGroup, assignUsersLoading } = useSelector((state: RootState) => state.groups)

  const displaySuccessTitle = () => {
    if (union(assignedUsers?.users_creation_success, assignedUsers?.users_assign_success).length === 1) {
      return (`1 ${(terms.Group.addedUser).toLowerCase()}`)
    }
    return (`${union(assignedUsers?.users_creation_success, assignedUsers?.users_assign_success).length}
      ${terms.Group.addedUsers}`)
  }

  return (
    <>
      <div className="d-flex creation-feedback flex-column justify-content-center align-items-center">
        {(assignedUsers?.users_creation_success?.length > 0 || assignedUsers?.users_assign_success?.length > 0) && (
          <>
            <div><SuccessIcon fill="#546EFE" /></div>

            <div className="creation-text">
              {displaySuccessTitle()}

            </div>
            <div className="creation-group-name">
              {selectedGroup.name}
            </div>

          </>
        )}
        {
          (!assignUsersLoading && assignedUsers?.users_assign_success?.length === 0
            && assignedUsers?.users_creation_success?.length === 0) && (
            <>
              <div><RoundInfoIcon fill="var(--color-red-500)" /></div>
              <div className="creation-text">{terms.Group.addFail}</div>
            </>
          )
        }
      </div>
      {(assignedUsers?.users_creation_fail?.length > 0 || assignedUsers?.users_assign_fail?.length > 0) && (
        <UsernameError
          errors={union(assignedUsers?.users_creation_fail, assignedUsers?.users_assign_fail)}
        />
      )}
      {assignedUsers?.users_creation_success?.length > 0 && (
        <UsernamesCreated usernames={assignedUsers.users_creation_success} />
      )}
      <div className="creation-list w-100">
        {(assignedUsers?.users_creation_success?.length > 0 || assignedUsers?.users_assign_success?.length > 0) && (
          (
            union(assignedUsers?.users_creation_success,
              assignedUsers?.users_assign_success) as string[]).map((name: string) => (
                <Chip className="username-chip" key={name} label={name} />))
        )}
      </div>

      <div className="d-flex w-75 align-items-end footer-modal justify-content-center">
        <Button
          onClick={handleClick}
          className="button-create"
          variant="contained"
        >

          {terms.Common.ok}

        </Button>

      </div>
    </>
  )
}
