import { ReactElement } from 'react'

import TabElement, { NavElement } from './TabElement'

type Props = {
  tabs: NavElement[]
}
export default function TabList({ tabs }: Props): ReactElement {
  return (
    <div className="d-flex align-item-center h-100 ml-3" style={{ flex: 1, borderLeft: '1px solid #687385' }}>
      {tabs.map(el => (
        <TabElement key={el.title} element={el} />
      ))}
    </div>
  )
}
