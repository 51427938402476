/* eslint-disable no-restricted-syntax */
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Collapse, IconButton, List, ListItem, ListItemText,
} from '@mui/material'
import {
  Fragment, ReactElement, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Service } from 'services/cerbereTypes'
import { RootState } from 'Store'
import './GroupList.scss'
import Item from './Item'

type Props = {
  services: Service[]
}
export default function GroupList({ services }: Props): ReactElement {
  const [open, setOpen] = useState<number[]>([])
  const { selectedGroup } = useSelector((state: RootState) => state.groups)

  const handleToggle = (index: number) => {
    const currentIndex = open.indexOf(index)
    const newOpen = [...open]
    if (currentIndex === -1) {
      newOpen.push(index)
    } else {
      newOpen.splice(currentIndex, 1)
    }
    setOpen(newOpen)
  }

  return (
    <div
      className="group-container"
      style={{ borderRight: selectedGroup?.id !== '' ? '1px solid var(--color-grey-100)' : 0 }}
    >
      <List>
        {services?.map((service: Service, index: number) => (
          <Fragment key={service.service_name}>
            <ListItem
              style={{ width: 'fit-content' }}
              disablePadding
            >
              <ListItemText className="group-title-text" primary={service.service_name} />
              <IconButton className="expand-icon" onClick={() => handleToggle(index)}>
                {open.indexOf(index) !== -1 ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItem>
            <Collapse
              in={open.indexOf(index) !== -1}
              timeout="auto"
              style={{ width: 'fit-content' }}
              unmountOnExit
            >
              {service.groups.map(group => (
                <Item
                  service={service.service_name}
                  key={group.id}
                  groups={group}
                  index={1}
                />
              ))}
            </Collapse>
          </Fragment>
        ))}

      </List>
    </div>
  )
}
