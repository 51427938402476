import { Paper } from '@mui/material'
import CustomTable from 'components/CustomTable/CustomTable'
import FeedbackSnackBar from 'components/FeedbackSnackBar/FeedbackSnackBar'
import TokenBar from 'components/TokenBar/TokenBar'
import TokenModal from 'components/TokenInfo/TokenInfo'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTokenModal, setTokenSearch } from 'reducers/token'
import TokenServices from 'services/TokenServices'
import { RootState } from 'Store'
import columnsHeader from './const'
import './Tokens.scss'

export default function Tokens(): ReactElement {
  const dispatch = useDispatch()
  const {
    showToken, tokens, tokensLoading, tokenSearch,
  } = useSelector((state: RootState) => state.tokens)

  useEffect(() => () => {
    dispatch(setTokenSearch(''))
    dispatch(setTokenModal(false))
  }, [])

  useEffect(() => {
    dispatch(TokenServices.getTokens({ search: tokenSearch }))
  }, [tokenSearch])

  return (
    <Paper className="user-main-container" elevation={0} square>
      <TokenBar length={tokens.length} />
      <CustomTable
        values={tokens}
        loader={tokensLoading}
        count={tokens.length}
        pagination={false}
        columns={columnsHeader}
        changePage={() => undefined}
      />

      {showToken && (<TokenModal />)}

      <FeedbackSnackBar />
    </Paper>
  )
}
