import { PERSIST, persistStore } from 'redux-persist'
import persistedReducer from 'reducers'
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { Dispatch, Action } from 'redux'

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [PERSIST],
    },
  }),
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => Dispatch<Action> = () => useDispatch<AppDispatch>()
