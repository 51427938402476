import { ReactComponent as RoundInfoIcon } from 'assets/icons/infoPanel.svg'
import terms from 'common/terms'
import { ReactElement } from 'react'
import './UsernamesCreated.scss'

type Props = {
  usernames: string[]
}

export default function UsernamesCreated({ usernames }: Props): ReactElement {
  return (
    <>
      <div className="d-flex w-100 info-container">
        <RoundInfoIcon width="20" height="20" fill="#546EFE" />
        <div className="username-creation-info">
          {`${usernames.length} `}
          {usernames.length > 1 ? terms.Group.infoCreatedUsers : terms.Group.infoCreatedUser}

          <div className="username-info-list">
            {usernames.map(name => `${name}`).join(', ')}

          </div>
        </div>
      </div>

    </>
  )
}
