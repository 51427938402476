import {
  Backdrop, Button, CircularProgress, InputBase,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

type Props = {
  onChange: (val: string) => void;
  onClick: (step: number) => void;
  usernames: string
  formatted: string[];
}
export default function FirstStep({
  onChange, onClick, usernames, formatted,
}: Props): ReactElement {
  const { createUserLoading } = useSelector((state: RootState) => state.users)
  return (
    <div style={{ position: 'relative' }} className="w-100">
      <Backdrop
        open={createUserLoading}
        className="backdrop"
      >
        <CircularProgress size={80} color="inherit" />
      </Backdrop>
      <div className="main-modal w-100">

        <div className="textfield-label">
          {`${terms.User.writeUsernames}. ${terms.Group.importInfo}`}
        </div>
        <InputBase
          className="username-textfield"
          multiline
          fullWidth
          rows={6}
          onChange={event => onChange(event.target.value)}
          value={usernames}
        />
      </div>

      <div className="d-flex w-100 row align-items-end justify-content-center footer-modal">
        <div className="d-flex w-100 justify-content-center noUsername-container">
          <Button onClick={() => onClick(4)} className="button-no-username">{terms.User.noUsername}</Button>
        </div>

        <div className="d-flex w-75 align-items-center justify-content-center">
          <Button
            disabled={formatted.length === 1 && !usernames.trim()}
            onClick={() => onClick(2)}
            className="button-add"
            variant="outlined"
          >

            {formatted.length > 1
              ? `${terms.User.addUsernames} ${formatted.length} 
            ${terms.User.username}` : terms.User.addUsername}

          </Button>

        </div>
      </div>
    </div>
  )
}
