import { ReactElement } from 'react'

import Groups from 'views/Groups/Groups'
import Help from 'views/Help/Help'
import Tokens from 'views/Tokens/Tokens'
import Users from 'views/Users/Users'

export const EDITOR_HOMEPATH = '/users'
export const USER_HOMEPATH = '/tokens'

export type RouteType = {
  path: string;
  component: () => ReactElement;
}

export const USER_ROUTES = [
  {
    path: '/tokens',
    component: Tokens,
  },
  {
    path: '/help',
    component: Help,
  },
]
export const EDITOR_ROUTES: Array<RouteType> = [
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/groups',
    component: Groups,
  },
  ...USER_ROUTES,
]
