/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ServiceResponse, TokenInfo, UserAssignResponse, UserResponse,
} from 'services/cerbereTypes'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const addUserToGroup = createAsyncThunk<any, any, ThunkApiConfig>(
  'groups/addUserToGroup',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/assign_user/${userId}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const removeUserFromGroup = createAsyncThunk<any, any, ThunkApiConfig>(
  'groups/removeUserFromGroup',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/remove_user/${userId}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroups = createAsyncThunk<ServiceResponse, string, ThunkApiConfig>(
  'groups/getGroups',
  async (search, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/groups/`, { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupPermissions = createAsyncThunk<TokenInfo, string, ThunkApiConfig>(
  'groups/getGroupPermissions',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/groups/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupMembers = createAsyncThunk<UserResponse, any, ThunkApiConfig>(
  'groups/getGroupMembers',
  async ({ page, groupId, search }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/groups/${groupId}/members`, { page, search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const assignAdmin = createAsyncThunk<any, any, ThunkApiConfig>(
  'groups/assignAdmin',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/assign_admin/${userId}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const removeAdmin = createAsyncThunk<any, any, ThunkApiConfig>(
  'groups/removeAdmin',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/remove_admin/${userId}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const assignUsers = createAsyncThunk<UserAssignResponse, any, ThunkApiConfig>(
  'groups/assignUsers',
  async ({ groupId, cp }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/assign_users/`, { cp })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const GroupServices = {
  addUserToGroup,
  removeUserFromGroup,
  getGroups,
  getGroupMembers,
  getGroupPermissions,
  assignAdmin,
  removeAdmin,
  assignUsers,
}

export default GroupServices
