import { Button, Chip } from '@mui/material'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import { ReactComponent as RoundInfoIcon } from 'assets/icons/infoPanel.svg'
import terms from 'common/terms'
import { ReactElement } from 'react'
import './SecondStep.scss'
import { RootState } from 'Store'
import { useSelector } from 'react-redux'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import UsernameError from './UsernameError'

type Props = {
  handleClick: () => void;
}

export default function SecondStep({ handleClick }: Props): ReactElement {
  const { createdUsers } = useSelector((state: RootState) => state.users)

  return (
    <>
      <div className="d-flex creation-feedback flex-column justify-content-center align-items-center">
        {createdUsers?.users_creation_success?.length ? (
          <>
            <div><SuccessIcon fill="#546EFE" /></div>

            <div className="creation-text">
              {createdUsers?.users_creation_success.length === 1
                ? terms.User.creationSuccess
                : `${createdUsers.users_creation_success.length} ${terms.User.createdUsers}`}

            </div>
          </>
        )
          : (
            <>
              <div><RoundInfoIcon fill="var(--color-red-500)" /></div>
              <div className="creation-text">{terms.User.creationFail}</div>
            </>
          )}
      </div>
      {createdUsers?.users_creation_fail?.length > 0 && (
        <UsernameError errors={createdUsers.users_creation_fail} />
      )}
      {'errorMessage' in createdUsers && (
        <div className="d-flex w-100 error-container">
          <WarningIcon width="20" height="20" fill="#E83F3F" />
          <div className="username-error">

            <div>{createdUsers?.errorMessage}</div>
          </div>
        </div>
      )}
      <div className="creation-list w-100">
        {createdUsers?.users_creation_success?.length > 0 && (
          createdUsers?.users_creation_success?.map((name: string) => (
            <Chip className="username-chip" key={name} label={name} />
          )))}
      </div>

      <div className="d-flex w-75 align-items-end footer-modal justify-content-center">
        <Button
          onClick={handleClick}
          className="button-create"
          variant="contained"
        >

          {terms.Common.ok}

        </Button>

      </div>
    </>
  )
}
