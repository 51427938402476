import { Paper } from '@mui/material'
import CustomTable from 'components/CustomTable/CustomTable'
import FeedbackSnackBar from 'components/FeedbackSnackBar/FeedbackSnackBar'
import UserBar from 'components/UserBar/UserBar'
import UserGroupBar from 'components/UserGroupBar/UserGroupBar'
import UserGroupManagement from 'components/UserGroupManagement/UserGroupManagement'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserSearch } from 'reducers/user'
import UserServices from 'services/UserServices'
import { RootState } from 'Store'
import columnsHeader from './const'
import './Users.scss'

export default function Users(): ReactElement {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const {
    showUserGroup, userSearch, users, usersLoading, createdUsers, sort,
  } = useSelector((state: RootState) => state.users)

  useEffect(() => () => {
    dispatch(resetUserSearch())
  }, [])

  const refreshUsers = () => {
    dispatch(UserServices.getUsers({ search: userSearch, sortItem: sort, page }))
  }

  useEffect(() => {
    if (page === 1) {
      refreshUsers()
    } else {
      setPage(1)
    }
  }, [userSearch, createdUsers, sort])

  useEffect(() => {
    refreshUsers()
  }, [page])

  return (
    <Paper className="user-main-container" elevation={0} square>
      {showUserGroup ? (
        <>
          <UserGroupBar />

          <div className="d-flex flex-wrap">
            <div style={{ width: '50%' }}>
              <UserGroupManagement mode="user" />
            </div>

            <div style={{ width: '50%' }}>
              <UserGroupManagement mode="group" />
            </div>
          </div>
        </>

      )
        : (
          <>
            <UserBar />
            <CustomTable
              values={users.results}
              loader={usersLoading}
              count={users.count}
              page_size={users.page_size}
              columns={columnsHeader}
              page={page}
              changePage={val => setPage(val)}
            />
          </>
        )}
      <FeedbackSnackBar />
    </Paper>
  )
}
