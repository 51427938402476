import { Modal, Paper } from '@mui/material'

import { usernameRule } from 'components/UserCreation/UserCreation'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGroupModal } from 'reducers/group'
import GroupServices from 'services/GroupServices'
import { RootState } from 'Store'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import './UsernamesImport.scss'

export default function UsernamesImport(): ReactElement {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [usernames, setUsernames] = useState('')
  const formattedUsernames = usernames.trim().split(usernameRule)
  const { assignUsersLoading, groupModal, selectedGroup } = useSelector((state: RootState) => state.groups)

  const assignUsers = () => {
    dispatch(GroupServices.assignUsers({ groupId: selectedGroup.id, cp: formattedUsernames }))
    setStep(2)
  }

  const handleClose = () => {
    setStep(1)
    setUsernames('')
    dispatch(setGroupModal(false))
  }

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      open={groupModal}
      onClose={handleClose}
    >
      <Paper
        className="d-flex row align-items-between justify-content-center user-creation-modal"
        elevation={0}
        square
      >
        {(step === 1 || (assignUsersLoading && step === 2))
          && (
            <FirstStep
              close={handleClose}
              onClick={assignUsers}
              usernames={usernames}
              formatted={formattedUsernames}
              onChange={setUsernames}
            />
          )}

        {step === 2 && !assignUsersLoading
          && (
            <SecondStep
              handleClick={handleClose}
            />
          )}
      </Paper>
    </Modal>
  )
}
