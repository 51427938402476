import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Token, TokenInfo } from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'
import TokenServices from 'services/TokenServices'

export interface TokenState {
  tokens: Token[];
  tokensLoading: boolean;
  tokenSearch: string;
  tokenInfo: TokenInfo;
  showToken: boolean;
  tokenInfoLoading: boolean;
}

const initialState: TokenState = {
  tokens: [],
  tokensLoading: true,
  tokenSearch: '',
  tokenInfo: { permissions: [], warning: '' },
  showToken: false,
  tokenInfoLoading: false,
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokenSearch: (state, action: PayloadAction<string>) => {
      state.tokenSearch = action.payload
    },
    setTokenModal: (state, action: PayloadAction<boolean>) => {
      state.showToken = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(TokenServices.getTokens.fulfilled, (state, action) => {
      state.tokens = action.payload
      state.tokensLoading = false
    })
    builder.addCase(TokenServices.getTokens.pending, state => {
      state.tokensLoading = true
    })
    builder.addCase(TokenServices.getTokenInfo.fulfilled, (state, action) => {
      state.tokenInfo = action.payload
      state.tokenInfoLoading = false
    })
    builder.addCase(TokenServices.getTokenInfo.pending, state => {
      state.tokenInfoLoading = true
    })
    builder.addCase(GroupServices.getGroupPermissions.fulfilled, (state, action) => {
      state.tokenInfo = action.payload
      state.tokenInfoLoading = false
    })
    builder.addCase(GroupServices.getGroupPermissions.pending, state => {
      state.tokenInfoLoading = true
    })
  },
})

export const {
  setTokenSearch, setTokenModal,
} = tokensSlice.actions

export default tokensSlice.reducer
