import { Switch } from '@mui/material'
import { ReactElement } from 'react'
import './CustomSwitch.scss'

type Props = {
  checked: boolean;
  onChange: (val: boolean) => void;
}
export default function CustomSwitch({ checked, onChange }: Props): ReactElement {
  return (<Switch onChange={(_e, newVal) => onChange(newVal)} checked={checked} />)
}

CustomSwitch.displayName = 'switch'
