import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete, CircularProgress, IconButton, TextField,
} from '@mui/material'
import terms from 'common/terms'
import { debounce } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddUsersMode, setGroupModal } from 'reducers/group'
import { User } from 'services/cerbereTypes'
import UserServices from 'services/UserServices'
import {
  RootState, store,
} from 'Store'
import AutocompleteOption from './AutocompleteOption'

type Props = {
  onSelect: (id: string) => void;
}

const debouncedSearch = debounce(
  (val: string) => store.dispatch(UserServices.getUsers({ search: val })),
  200,
)

const emptyUser = {
  id: '0',
  username: '0',
  createdTimestamp: '0',
  available_groups: [],
  emailVerified: false,
  requiredAction: [],
  groups: [],
}
export default function CustomAutocomplete({ onSelect }: Props): ReactElement {
  const dispatch = useDispatch()
  const { groupMembers } = useSelector((state: RootState) => state.groups)
  const { users, usersLoading } = useSelector((state: RootState) => state.users)
  const [search, setSearch] = useState<string | null>(null)
  const [user, setUser] = useState<User>({ ...emptyUser, id: '-1' })

  useEffect(() => {
    setUser({ ...emptyUser, id: '-1' })
    setSearch(null)
  }, [groupMembers])

  const handleTextfield = (val: string) => {
    setSearch(val)
    debouncedSearch(val)
  }

  const handleChangeOption = (val: User) => {
    if (val.id === '0') {
      dispatch(setGroupModal(true))
      return
    }
    setUser({ ...emptyUser, id: '-1' })
    setSearch(null)
    onSelect(val.id)
  }

  const changeMode = () => {
    dispatch(setAddUsersMode(false))
  }
  return (
    <Autocomplete
      fullWidth
      className="autocomplete"
      filterOptions={x => (x.slice(0, 5)).concat(emptyUser)}
      options={users.results}
      value={user}
      freeSolo
      openOnFocus
      onChange={(_e, val) => handleChangeOption(val as User)}
      disableClearable
      noOptionsText={terms.Common.noResults}
      getOptionLabel={() => ''}
      renderOption={(props, option) => ([
        <AutocompleteOption
          key={option.id}
          props={props}
          user={option}
        />,
      ])}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          placeholder={terms.Group.searchUserToAdd}
          className="textfield-autocomplete"
          InputProps={{
            ...params.InputProps,
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.stopPropagation()
              }
            },
            disableUnderline: true,
            startAdornment: <SearchIcon />,
            endAdornment: (
              <>
                {(usersLoading) && (<CircularProgress size="24px" style={{ marginRight: '10px' }} />)}
                <IconButton
                  size="small"
                  onClick={changeMode}
                >

                  <ClearIcon />
                </IconButton>
              </>
            ),
          }}
          value={search}
          onChange={e => handleTextfield(e.target.value)}
        />
      )}
    />
  )
}
