import terms from 'common/terms'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTokenSearch } from 'reducers/token'
import { RootState, store } from 'Store'
import './TokenBar.scss'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setTokenSearch(val)),
  200,
)
type Props = {
  length: string;
}
export default function TokenBar({ length }: Props): ReactElement {
  const dispatch = useDispatch()
  const { tokensLoading, tokenSearch } = useSelector((state: RootState) => state.tokens)

  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setTokenSearch(value))
      return
    }
    debouncedSearch(value)
  }

  return (
    <div className="token-bar">
      <div className="token-count">
        {!tokensLoading && (
          <span>
            {`${length} ${(terms.Token.tokens).toLowerCase()}`}
          </span>
        )}

      </div>

      <div className="token-buttons-container">
        <SearchInput value={tokenSearch} onChange={newValue => handleChange(newValue)} />
      </div>
    </div>
  )
}
