import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import LoadingButton from '@mui/lab/LoadingButton'
import terms from 'common/terms'
import Loader from 'components/Loader'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import {
  ReactElement, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserGroupSearch } from 'reducers/user'
import UserServices from 'services/UserServices'
import { RootState, store } from 'Store'
import GroupList from './GroupList'
import './UserGroupManagement.scss'

type Props = {
  mode: string;
}

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setUserGroupSearch(val)),
  200,
)

export default function UserGroupManagement({ mode }: Props): ReactElement {
  const dispatch = useDispatch()
  const {
    usersLoading, userGroupSearch, userInfo, userAssign, userAssignLoading, showUserGroup,
  } = useSelector((state: RootState) => state.users)
  const [groupsToRemove, setToRemove] = useState<string[]>([])
  const [groupsToAdd, setToAdd] = useState<string[]>([])
  const [groupLength, setLength] = useState<number>(0)

  const modifyGroups = (groups: string[]) => {
    if (mode === 'user') {
      setToRemove(groups)
    } else {
      setToAdd(groups)
    }
  }

  useEffect(() => () => {
    dispatch(setUserGroupSearch(null))
  }, [])

  useEffect(() => {
    if (showUserGroup) {
      setToRemove([])
      setToAdd([])
      dispatch(UserServices.getUser({ id: showUserGroup, search: userGroupSearch }))
    }
  }, [userAssign, userGroupSearch])

  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setUserGroupSearch(value))
      return
    }
    debouncedSearch(value)
  }

  const addToGroup = async () => {
    dispatch(UserServices.assignToGroups({ groups: groupsToAdd, userId: userInfo.id }))
  }

  const removeFromGroup = async () => {
    dispatch(UserServices.removeFromGroups({ groups: groupsToRemove, userId: userInfo.id }))
  }
  const renderUserHeader = () => {
    if ((!usersLoading)) {
      return (
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="text">

            {groupLength === 0 && terms.User.noGroups}
            {groupLength === 1 && terms.User.memberOfOne}
            {groupLength > 1 && `
            ${terms.User.memberOf}
            ${groupLength}
            ${terms.User.followingGroups}
            :`}
          </div>
          {groupsToRemove.length > 0 && (
            <LoadingButton
              disableElevation
              disabled={userAssignLoading}
              loading={userAssignLoading}
              loadingPosition="start"
              startIcon={<RemoveIcon />}
              variant="contained"
              onClick={removeFromGroup}
              size="large"
              className="modify-groups"
            >
              {groupsToRemove.length === 1 ? terms.User.removeFromGroup
                : `${terms.User.remove}
              ${groupsToRemove.length}
              ${(terms.Group.groups).toLowerCase()}`}
            </LoadingButton>
          )}
        </div>
      )
    }
    return ''
  }

  const renderGroupHeader = () => (
    <div className="d-flex h-100 w-100 justify-content-between align-items-center">
      <div className="text">
        {`${terms.User.availableGroups} :`}
      </div>

      <div className="manage-buttons-container">
        <div className="search">
          <SearchInput value={userGroupSearch} onChange={handleChange} />
        </div>
        {groupsToAdd.length > 0 && (
          <LoadingButton
            disableElevation
            disabled={userAssignLoading}
            loading={userAssignLoading}
            loadingPosition="start"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={addToGroup}
            className="modify-groups"
          >
            {groupsToAdd.length === 1 ? terms.Group.addToGroup
              : `${terms.Common.add}
              ${terms.Common.to}
              ${groupsToAdd.length}
              ${(terms.Group.groups).toLowerCase()}`}

          </LoadingButton>
        )}
      </div>
    </div>
  )

  const renderUserBody = () => {
    if ((usersLoading && userGroupSearch === null) || userAssignLoading) {
      return (
        <div
          className="loading"
        >
          <Loader />
        </div>
      )
    }
    return (
      <GroupList setLength={val => setLength(val)} setGroups={modifyGroups} groups={userInfo.groups} />
    )
  }

  const renderGroupBody = () => {
    if (usersLoading || userAssignLoading) {
      return (
        <div
          className="loading"
        >
          <Loader />
        </div>
      )
    }
    return (
      <GroupList setLength={() => undefined} mode="group" setGroups={modifyGroups} groups={userInfo.available_groups} />
    )
  }

  return (
    <>
      <div className="manage-groups">
        {mode === 'user'
          ? renderUserHeader()
          : renderGroupHeader()}
      </div>
      <div className="list-groups">
        {mode === 'user' ? renderUserBody() : renderGroupBody()}
      </div>
    </>
  )
}
