import { Group, Service } from 'services/cerbereTypes'
import { store } from 'Store'

export const checkChildren = (grp: Group, selected: Group, found: boolean): boolean => {
  grp?.children?.forEach((child: Group) => {
    if (child.id === selected.id) {
      found = grp.can_managed as boolean
    }
    if (found) {
      return found
    }
    checkChildren(child, selected, false)
    return false
  })
  return false
}

export const checkRole = (groups: Service[], selected: Group, property: keyof Group): boolean => {
  let found = false
  groups.map((service: Service) => {
    service.groups.forEach(grp => {
      if (grp.id === selected.id) {
        found = grp[property] as boolean
      }
      checkChildren(grp, selected, found)
    })
    return found
  })
  return found
}

export const canDelegate = (): boolean => store.getState().groups.selectedGroup?.can_delegate

export const canManage = (): boolean => store.getState().groups.selectedGroup?.can_managed
