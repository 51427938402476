import { Button } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

type Props = {
  value: string;
  onClick: () => void;
  startIcon?: ReactNode;
} & typeof defaultProps;

const defaultProps = {
  startIcon: <></>,
}

export default function CustomButton({ value, startIcon, onClick }: Props): ReactElement {
  return (
    <Button
      className="custom-button"
      variant="contained"
      onClick={onClick}
      startIcon={startIcon}
    >
      {value}
    </Button>
  )
}

CustomButton.defaultProps = defaultProps
