import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import SearchInput from 'components/SearchInput/SearchInput'
import UserCreation from 'components/UserCreation/UserCreation'
import { canAddOutsideUser } from 'helpers/permissions'
import { debounce } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCreateModal, setSearch } from 'reducers/user'
import { RootState, store } from 'Store'
import './UserBar.scss'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setSearch(val)),
  200,
)

export default function UserBar(): ReactElement {
  const dispatch = useDispatch()
  const { users, usersLoading, userSearch } = useSelector((state: RootState) => state.users)
  const [init, setInit] = useState<boolean>(false)

  useEffect(() => {
    if (!init && !usersLoading) {
      setInit(true)
    }
  }, [usersLoading])

  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setSearch(value))
      return
    }
    debouncedSearch(value)
  }

  return (
    <div className="user-bar">
      <div className="user-count">
        {init && (<span>{`${users.count} ${terms.User.users}`}</span>)}
      </div>

      <div className="user-buttons-container">
        <SearchInput value={userSearch} onChange={newValue => handleChange(newValue)} />
        {canAddOutsideUser() && (
          <CustomButton
            onClick={() => dispatch(setCreateModal(true))}
            value={terms.User.create}
            startIcon={<PersonAddAltIcon />}
          />
        )}

      </div>
      <UserCreation />
    </div>
  )
}
