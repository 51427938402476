import { ReactElement, useState } from 'react'
import './CustomCopyCell.scss'
import { Button, Tooltip } from '@mui/material'
import terms from 'common/terms'

type Props = {
  value: string;
}
export default function CustomCopyCell({ value }: Props): ReactElement {
  const [isHovered, setHover] = useState(false)
  const [showTooltip, setTooltip] = useState(false)

  const handleHover = () => {
    if (!isHovered) {
      setHover(true)
    }
  }

  const handleOut = () => {
    setHover(false)
  }

  const copyToClipboard = () => {
    setTooltip(true)
    navigator.clipboard.writeText(value)
  }

  return (
    <Tooltip
      open={showTooltip}
      title={terms.Common.copied}
      placement="bottom"
      onClose={() => setTooltip(false)}
      arrow
    >
      <div
        className={isHovered ? 'copy-container-hover' : 'copy-container'}
        onMouseEnter={handleHover}
        onMouseLeave={handleOut}
        onFocus={() => 0}
        onBlur={() => 0}
      >
        <span className={isHovered ? 'copy-reduced-text' : 'copy-full-text'}>
          {value}
        </span>

        {isHovered && (
          <Button
            className="copy-button"
            style={{ width: '20%' }}
            onClick={copyToClipboard}
          >
            {terms.Common.copy}
          </Button>
        )}
      </div>
    </Tooltip>
  )
}
