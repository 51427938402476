import CustomTable from 'components/CustomTable/CustomTable'
import UsernamesImport from 'components/UsernamesImport/UsernamesImport'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMemberSearch } from 'reducers/group'
import GroupServices from 'services/GroupServices'
import { RootState } from 'Store'
import columnsHeader from './const'
import DetailsBar from './DetailsBar'
import './GroupsDetails.scss'

export default function GroupDetails(): ReactElement {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const {
    selectedGroup, groupMembers, membersLoading, memberSearch, userRemoved, userStatusChanged, userAdded, assignedUsers,
  } = useSelector((state: RootState) => state.groups)

  const refreshMembers = () => {
    dispatch(GroupServices.getGroupMembers({ page, groupId: selectedGroup.id, search: memberSearch }))
  }
  useEffect(() => {
    refreshMembers()
  }, [page])

  useEffect(() => {
    if (userRemoved || userStatusChanged || userAdded) {
      refreshMembers()
    }
  }, [userRemoved, userStatusChanged, userAdded])

  useEffect(() => {
    dispatch(setMemberSearch(''))
    if (page === 1) {
      refreshMembers()
      return
    }
    setPage(1)
  }, [assignedUsers, selectedGroup])

  useEffect(() => {
    if (page === 1) {
      refreshMembers()
      return
    }
    setPage(1)
  }, [memberSearch])

  return (
    <div className="details-main">
      <DetailsBar loading={membersLoading} length={groupMembers.count} group={selectedGroup} />
      <CustomTable
        values={groupMembers.results}
        loader={membersLoading}
        count={groupMembers.count}
        page_size={groupMembers.page_size}
        columns={columnsHeader}
        page={page}
        changePage={val => setPage(val)}
      />

      <UsernamesImport />
    </div>
  )
}
