import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'
import { ResponseError } from 'types'
import { allErrorsMatcher, allPendingsMatcher } from './matchers/matchers'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
  messageReady?: boolean;

}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
  messageReady: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setMessageReady: (state, action: PayloadAction<boolean>) => {
      state.messageReady = action.payload
    },
  },
  extraReducers: builder => {
    // Update handling
    builder.addCase(UserServices.enableUser.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'User.enableSuccess' } }
    })
    builder.addCase(UserServices.disableUser.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'User.disableSuccess' } }
    })
    builder.addCase(UserServices.enableUser.pending, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
    builder.addCase(UserServices.disableUser.pending, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
    builder.addCase(UserServices.createUser.rejected, (state, action) => {
      if (action.payload?.code === 500) {
        state.feedback = { code: 500, data: {} }
      }
    })

    builder.addCase(GroupServices.addUserToGroup.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.addedUser' } }
    })
    builder.addCase(GroupServices.removeUserFromGroup.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.removedUser' } }
    })
    builder.addCase(UserServices.assignToGroups.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modificationSuccess' } }
    })
    builder.addCase(UserServices.removeFromGroups.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modificationSuccess' } }
    })
    builder.addCase(UserServices.assignToGroups.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.feedback = { code: 400, data: { message: 'Group.partialModification' } }
      }
    })
    builder.addCase(UserServices.removeFromGroups.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.feedback = { code: 400, data: { message: 'Group.modifiedUser' } }
      }
    })
    builder.addCase(GroupServices.assignAdmin.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modifiedUser' } }
    })
    builder.addCase(GroupServices.removeAdmin.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modifiedUser' } }
    })
    builder.addCase(GroupServices.addUserToGroup.rejected, (state, action) => {
      if (action.payload?.code === 400 && action.payload?.data) {
        state.messageReady = true
        state.feedback = { code: 400, data: { message: action.payload.data } }
      } else {
        state.feedback = { code: action.payload?.code as number, data: { message: '' } }
      }
    })
    // Error Handling
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload
    })
    builder.addMatcher(allPendingsMatcher, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
  },
})

export const { setError, setMessage, setMessageReady } = feedbackSlice.actions

export default feedbackSlice.reducer
