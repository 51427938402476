import {
  Backdrop, Button, CircularProgress, IconButton, InputBase,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  close: () => void;
  onChange: (val: string) => void;
  onClick: (step: number) => void;
  usernames: string
  formatted: string[];
}
export default function FirstStep({
  close, onChange, onClick, usernames, formatted,
}: Props): ReactElement {
  const { assignUsersLoading } = useSelector((state: RootState) => state.groups)
  return (
    <>
      <div className="d-flex row w-100 align-items-start header-modal justify-content-between">
        <div className="title">{terms.Group.importUsernames}</div>

        <IconButton onClick={close} className="close-button">
          <CloseIcon />
        </IconButton>

      </div>
      <div style={{ position: 'relative' }} className="w-100">
        <Backdrop
          open={assignUsersLoading}
          className="backdrop"
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
        <div className="main-modal w-100">

          <div className="textfield-label">
            {`${terms.User.writeUsernames}. ${terms.Group.importInfo}`}
          </div>
          <InputBase
            className="username-textfield"
            multiline
            fullWidth
            rows={6}
            onChange={event => onChange(event.target.value)}
            value={usernames}
          />
        </div>

        <div className="d-flex w-100 row align-items-end justify-content-center footer-modal">

          <div className="d-flex w-75 align-items-center justify-content-center">
            <Button
              disabled={formatted.length === 1 && !usernames.trim()}
              onClick={() => onClick(2)}
              className="button-add"
              variant="outlined"
            >

              {formatted.length > 1
                ? `${terms.User.addUsernames} ${formatted.length} 
            ${terms.User.username}` : terms.User.addUsername}

            </Button>

          </div>
        </div>
      </div>
    </>
  )
}
