import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton, Modal, Paper,
} from '@mui/material'
import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTokenModal } from 'reducers/token'
import { RootState } from 'Store'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import FeedbackSnackBar from 'components/FeedbackSnackBar/FeedbackSnackBar'
import columnsHeader from './const'
import './TokenInfo.scss'

export default function TokenModal(): ReactElement {
  const dispatch = useDispatch()
  const { showToken, tokenInfo, tokenInfoLoading } = useSelector((state: RootState) => state.tokens)

  const handleClose = () => {
    dispatch(setTokenModal(false))
  }

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      open={showToken}
      onClose={handleClose}
    >
      <Paper
        className="d-flex row token-modal"
        elevation={0}
        square
      >
        <div className="d-flex row w-100 align-items-start header-modal justify-content-between">
          <div className="title">{terms.Token.permissionList}</div>

          <IconButton onClick={handleClose} className="close-button">
            <CloseIcon />
          </IconButton>

        </div>

        {!tokenInfoLoading
          && (tokenInfo.warning && (
            <div className="d-flex w-100">
              <WarningIcon width="20" height="20" fill="#E83F3F" />
              <div className="token-error">
                {tokenInfo.warning}
              </div>
            </div>
          ))}

        <CustomTable
          values={tokenInfo?.permissions}
          loader={tokenInfoLoading}
          count={tokenInfo.length}
          pagination={false}
          columns={columnsHeader}
          changePage={() => undefined}
        />

        <FeedbackSnackBar />
      </Paper>
    </Modal>
  )
}
