import { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './TabElement.scss'

export type NavElement = {
  icon: string;
  title: string;
  path: string;
}

type Props = {
  element: NavElement;
}

export default function TabElement({ element }: Props): ReactElement {
  const { icon, title, path } = element

  const location = useLocation()

  const isSelected = location.pathname.includes(path)

  return (
    <Link to={path}>
      <div
        className={`tab-element d-flex justify-content-center align-items-center 
        h-100 px-3 ${isSelected ? 'selected' : ''}`}
      >
        <div className="mr-2">
          <img src={icon} alt="tab icon" />
        </div>
        <div className="title">
          {title}
        </div>
      </div>
    </Link>
  )
}
