import { Link } from '@mui/material'
import { Column } from 'components/CustomTable/types'
import { ReactElement } from 'react'

const columnsHeader: Column[] = [
  {
    propertyName: 'role_name',
    align: 'left',
    width: '200px',
    copy: false,
    formatter: (o: unknown): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'doc_link',
    align: 'left',
    width: '230px',
    copy: false,
    formatter: (o: unknown): ReactElement => (
      <Link
        target="_blank"
        href={o as string}
      >
        {o as string}
      </Link>
    ),
    show: () => true,
  },
]

export default columnsHeader
