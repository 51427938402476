import { Chip } from '@mui/material'
import terms from 'common/terms'
import { getCSSValue } from 'helpers'
import { get } from 'lodash'
import { ReactElement } from 'react'
import './StatusChip.scss'

type Props = {
  value: string;
}

export default function StatusChip({ value }: Props): ReactElement {
  const getCustomStyle = () => {
    switch (value) {
      case 'pending':
      case 'pending_delete':
        return getCSSValue('--color-pending-token')

      case 'accepted':
      case 'owned':
        return getCSSValue('--color-valid-token')

      case 'expired':
      case 'disable':
      case 'rejected':
        return getCSSValue('--color-rejected-token')

      default:
        return getCSSValue('--color-pending-token')
    }
  }
  return (
    <Chip sx={{ backgroundColor: getCustomStyle() }} className="statusChip" label={get(terms.Token, value)} />
  )
}
