/* eslint-disable import/prefer-default-export */
import { isAnyOf } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'

export const postPendingMatcher = isAnyOf(
  GroupServices.addUserToGroup.pending,
  GroupServices.removeUserFromGroup.pending,
  GroupServices.assignAdmin.pending,
  GroupServices.removeAdmin.pending,
)

export const postErrorMatcher = isAnyOf(
  GroupServices.removeUserFromGroup.rejected,
  GroupServices.assignAdmin.rejected,
  GroupServices.removeAdmin.rejected,
)
