/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Token, TokenInfo } from 'services/cerbereTypes'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const getTokens = createAsyncThunk<Token[], any, ThunkApiConfig>(
  'groups/getTokens',
  async ({ search }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/tokens/`, { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTokenInfo = createAsyncThunk<TokenInfo, any, ThunkApiConfig>(
  'groups/getTokenInfo',
  async ({ id }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/tokens/${id}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TokenServices = {
  getTokens, getTokenInfo,
}

export default TokenServices
