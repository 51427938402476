import { Chip } from '@mui/material'
import terms from 'common/terms'
import { getCSSValue } from 'helpers'
import { get } from 'lodash'
import { ReactElement } from 'react'
import './TypeChip.scss'

type Props = {
  value: string;
}

export default function TypeChip({ value }: Props): ReactElement {
  return (
    <Chip
      sx={{ backgroundColor: getCSSValue(`--color-${value}-token`) }}
      className="typeChip"
      label={get(terms, `Token.${value}`)}
    />
  )
}
