import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import { ReactElement, useEffect, useState } from 'react'
import { UserFailure } from 'services/cerbereTypes'
import { UnknownObject } from 'types'
import './UsernameError.scss'

type Props = {
  errors: UserFailure[]
}

export default function UsernameError({ errors }: Props): ReactElement {
  const [groupedErrors, setErrors] = useState<UnknownObject>({})

  const groupBy = () => {
    const result = errors.reduce((r, a) => {
      r[a.errorMessage] = r[a.errorMessage] || []
      r[a.errorMessage].push(a.cp)
      return r
    }, Object.create(null))
    return result
  }

  useEffect(() => {
    setErrors(groupBy())
  }, [])

  return (
    <>
      <div className="d-flex w-100 error-container">
        {Object.keys(groupedErrors).map(type => (
          <>
            <div className="error-full-message">
              <WarningIcon width="20" height="20" fill="#E83F3F" />
              <div className="username-error">
                {type}

                <div className="error-list">
                  {((groupedErrors)[type] as string[]).map(username => (
                    username
                  )).join(', ')}
                </div>
              </div>
            </div>
          </>
        ))}

      </div>

    </>
  )
}
