import CloseIcon from '@mui/icons-material/Close'
import { Breadcrumbs, ButtonBase, IconButton } from '@mui/material'
import { ReactComponent as ChevronRight } from 'assets/icons/chevronRight.svg'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserGroup, setUserGroupSearch } from 'reducers/user'
import { RootState } from 'Store'
import './UserGroupBar.scss'

export default function UserGroupBar(): ReactElement {
  const dispatch = useDispatch()
  const { userInfo, usersLoading } = useSelector((state: RootState) => state.users)

  const closeMenu = () => {
    dispatch(setUserGroup(''))
    dispatch(setUserGroupSearch(''))
  }

  const displayName = () => {
    if (!userInfo.firstName || !userInfo.lastName) {
      return `${terms.User.manageGroup} ${userInfo.username}`
    }
    return `${terms.User.manageGroup} ${userInfo.firstName} ${userInfo.lastName}`
  }
  return (
    <div className="usergroup-bar">
      <div className="usergroup-breadcrumbs">
        <Breadcrumbs separator={<ChevronRight />} aria-label="breadcrumb">
          <ButtonBase className="home-button" onClick={closeMenu}>
            {terms.User.users}
          </ButtonBase>
          <div className="group-breadcrumb">
            {!usersLoading
              && displayName()}
          </div>
        </Breadcrumbs>
      </div>
      <div className="usergroup-close">
        <IconButton onClick={closeMenu}>
          <CloseIcon />
        </IconButton>
      </div>

    </div>
  )
}
