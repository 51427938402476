import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { canDelegate, canManage } from 'components/GroupDetails/utils'
import { MouseEvent, ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { User } from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'
import { RootState } from 'Store'
import './GroupAction.scss'

type Props = {
  userId: string;
}
export default function GroupAction({ userId }: Props): ReactElement {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { selectedGroup, groupMembers } = useSelector((state: RootState) => state.groups)
  const open = Boolean(anchorEl)

  const isAdmin = (id: string) => {
    const member = (groupMembers.results.find((elem: User) => (elem.username === id)
      || (elem.id === id)))
    return member?.user_can_delegate
  }

  const isMember = (id: string) => {
    const member = (groupMembers.results.find((elem: User) => (elem.username === id)
      || (elem.id === id)))
    return member?.is_member
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    dispatch(GroupServices.removeUserFromGroup({ groupId: selectedGroup.id, userId }))
    handleClose()
  }

  const handleAdd = () => {
    dispatch(GroupServices.addUserToGroup({ groupId: selectedGroup.id, userId }))
    handleClose()
  }

  const changeStatus = (mode: string) => {
    if (mode === 'assign') {
      dispatch(GroupServices.assignAdmin({ groupId: selectedGroup.id, userId }))
    } else {
      dispatch(GroupServices.removeAdmin({ groupId: selectedGroup.id, userId }))
    }
    handleClose()
  }

  const renderAdminChange = (id: string) => {
    if (canDelegate()) {
      if (isAdmin(id)) {
        return (
          <MenuItem onClick={() => changeStatus('remove')}>{terms.Group.removeAdmin}</MenuItem>
        )
      }
      return (
        <MenuItem onClick={() => changeStatus('assign')}>{terms.Group.assignAdmin}</MenuItem>
      )
    }
    return ''
  }

  const renderMemberChange = (id: string) => {
    if (canManage()) {
      if (isMember(id)) {
        return (
          <MenuItem onClick={handleDelete}>{terms.Group.removeMember}</MenuItem>
        )
      }
      return (
        <MenuItem onClick={handleAdd}>{terms.Group.assignMember}</MenuItem>
      )
    }
    return ''
  }

  return (
    <>
      <IconButton onClick={handleClick}><MoreHorizIcon /></IconButton>

      <Menu
        className="manage-users-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {renderMemberChange(userId)}
        {renderAdminChange(userId)}
      </Menu>
    </>
  )
}
