import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Input } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useEffect, useState } from 'react'
import './SearchInput.scss'
import ClearIcon from '@mui/icons-material/Clear'

type Props = {
  value: string;
  onChange: (val: string) => void;

}
export default function SearchInput({ value, onChange }: Props): ReactElement {
  const [val, setVal] = useState(value)

  const changeVal = (v: string) => {
    setVal(v)
    onChange(v)
  }
  useEffect(() => {
    setVal(value)
  }, [value])
  return (
    <Input
      className="search-input"
      startAdornment={<SearchIcon />}
      disableUnderline
      placeholder={terms.User.search}
      value={val === null ? '' : val}
      onChange={evt => changeVal(evt.target.value)}
      endAdornment={val && (
        <IconButton
          style={{ marginRight: '10px' }}
          size="small"
          onClick={() => changeVal('')}
        >
          <ClearIcon />

        </IconButton>
      )}
    />
  )
}
