import { Chip } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { Group, User } from 'services/cerbereTypes'
import './RoleCell.scss'

type Props = {
  user: User | Group;
}
export default function RoleCell({ user }: Props): ReactElement {
  return (
    <div className="role-cell">
      {user.user_can_delegate && (<Chip className="admin-chip" label={terms.Group.admin} />)}
      {user.is_member && (<Chip className="member-chip" label={terms.Group.member} />)}
    </div>

  )
}
